import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
export default defineComponent({
    name: 'AboutUs',
    setup() {
        const imgContainer = ref(null);
        const imgStyle = ref({});
        const scrollHandle = () => {
            if (!imgContainer.value)
                return;
            const rect = imgContainer.value.getBoundingClientRect();
            const top = (rect.top - window.innerHeight / 2) * -1;
            const translate = Math.max(top / window.innerHeight, 0) * 60;
            const isMobile = matchMedia('(max-width: 767px)').matches;
            let distance = 30;
            let shift = 0;
            if (!isMobile) {
                distance = Math.max((rect.width / 1920) * 12, 12);
                shift = Math.min((rect.width / 1920) * 30, 30);
            }
            let rightEdgePosY = 75;
            if (top < 0) {
                distance -= distance * Math.min(Math.abs(top / rect.height), 1);
                rightEdgePosY -= 10 * Math.min(Math.abs(top / rect.height), 1);
            }
            const leftBase = 60;
            const leftLine = leftBase - shift - translate;
            const rightBase = leftBase + distance;
            const rightLine = rightBase - shift + translate;
            const calcEndPos = (linePos, heightPercent) => rect.width * (linePos / 100) -
                (rect.height - heightPercent * rect.height);
            const leftEdgePosY = 65;
            const leftEnd = calcEndPos(leftLine, leftEdgePosY / 100);
            const rightEnd = calcEndPos(rightLine, rightEdgePosY / 100);
            imgStyle.value = {
                clipPath: `polygon(
                        ${leftLine}% 0,
                        ${rightLine}% 0,
                        ${rightLine}% ${rightEdgePosY}%,
                        ${rightEnd}px 100%,
                        ${leftEnd}px 100%,
                        ${leftLine}% ${leftEdgePosY}%
                    )`,
            };
        };
        window.addEventListener('scroll', scrollHandle);
        window.addEventListener('resize', scrollHandle);
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', scrollHandle);
            window.removeEventListener('resize', scrollHandle);
        });
        onMounted(() => scrollHandle());
        return { imgStyle, imgContainer };
    },
});
